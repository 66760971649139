import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import CircularProgress from '@mui/material/CircularProgress';

const StlObject = ({ geometry, color, visible }) => {
  const meshRef = useRef();

  useEffect(() => {
    if (meshRef.current && geometry) {
      meshRef.current.geometry = geometry;
      meshRef.current.geometry.computeVertexNormals();
    }
  }, [geometry]);

  if (!geometry) return null;

  return (
    <mesh ref={meshRef} scale={[0.95, 0.95, 0.95]} visible={visible}>
      <primitive object={geometry} />
      <meshStandardMaterial 
        color={color} 
        roughness={1} 
        metalness={0}
        side={THREE.DoubleSide}
        polygonOffset={true}
        polygonOffsetFactor={1}
        polygonOffsetUnits={1}
      />
    </mesh>
  );
};


const Scene = ({ models }) => {
  const groupRef = useRef();
  const controlsRef = useRef();
  const { camera, scene } = useThree();

  useEffect(() => {
    if (groupRef.current) {
      const box = new THREE.Box3().setFromObject(groupRef.current);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());

      const zoomOutFactor = 1.25;

      camera.position.set(center.x, center.y, size.length() * zoomOutFactor);
      camera.lookAt(center);
      camera.updateProjectionMatrix();

      if (controlsRef.current) {
        controlsRef.current.target.set(center.x, center.y, center.z);
        controlsRef.current.update();
      }
    }
  }, [models, camera, scene]);

  useFrame(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  return (
    <>
      <group ref={groupRef}>
        {models.map((model, index) => (
          <StlObject key={index} geometry={model.geometry} color={model.color} visible={model.visible} />
        ))}
      </group>
      <OrbitControls 
        ref={controlsRef}
        enablePan={true} 
        enableRotate={true} 
        enableZoom={true}
        maxPolarAngle={Math.PI}
        minPolarAngle={0}
        dampingFactor={0.1}
      />
    </>
  );
};

const StlViewer = ({ urls, height, visibleModels, selectedQuality, loadedQualities }) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedGeometries, setLoadedGeometries] = useState({});

  useEffect(() => {
    console.log("StlViewer: Loading models", urls, loadedQualities);
    const loader = new STLLoader();
    const loadModels = async () => {
      setLoading(true);
      const newLoadedGeometries = { ...loadedGeometries };
      const loadedModels = await Promise.all(
        urls.filter(url => loadedQualities.includes(url.type)).map(async (url) => {
          if (!newLoadedGeometries[url.url]) {
            const geometry = await new Promise((resolve) => loader.load(url.url, resolve));
            newLoadedGeometries[url.url] = geometry;
          }
          const visible = visibleModels.includes(url.name) && 
                          (url.type === selectedQuality[0] || (selectedQuality[0] === 'default' && url.type === 'default'));
          return { ...url, geometry: newLoadedGeometries[url.url], visible };
        })
      );
      setLoadedGeometries(newLoadedGeometries);
      console.log("StlViewer: Models loaded", loadedModels);
      setModels(loadedModels);
      setLoading(false);
    };
    loadModels();
  }, [urls, visibleModels, selectedQuality, loadedQualities]);


  if (loading || models.length === 0) {
    return (
      <div style={{ height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ height }}>
      <Canvas
        gl={{ antialias: true, alpha: true }}
        camera={{ fov: 75, near: 0.1, far: 1000 }}
      >
        <color attach="background" args={['#000000']} />
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={1} />
        <directionalLight position={[-5, 5, 5]} intensity={0.5} />
        <Scene models={models} />
      </Canvas>
    </div>
  );
};

export default StlViewer;